import React, { useState } from "react"
import styled from "styled-components"
import { useForm, Controller } from "react-hook-form"
import Loader from "../../../ui/Loader"
import { useTranslation } from "react-i18next"
import Button from "../../../ui/Button"
import { subscribeToOutOfStock } from "../../../context/utils/events/klaviyoEvents"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

const Title = styled.h4`
  text-transform: uppercase;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
`

const Message = styled.div`
  letter-spacing: 0.1rem;
  margin: 1.5rem 1rem 0;
  display: flex;
  align-items: center;

  i {
    font-size: 1.8rem;
    margin: 0 0.5rem 0.1rem 0;
  }
`

const StyledInput = styled.input`
  height: 5rem;
  width: 100%;
  padding: 0 0.1rem 0 2rem;
  border: none;
  text-transform: none;
  margin-bottom: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.darkBorder};

  &::placeholder {
    color: ${(p) => p.theme.colors.darkGrey};
  }

  &:focus {
    outline: none;
  }
`

const SubTitle = styled.p`
  text-align: center;
  margin: 0 0 2rem;
`

const Success = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 30rem;

  i {
    font-size: 3rem;
    color: ${(p) => p.theme.colors.success};
  }
`

const SubmitButton = styled(Button)`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
`

const OutOfStockForm = ({ variantId, productId }) => {
  const { t } = useTranslation("translations")
  const [isLoading, setIsLoading] = useState(false)
  const [klaviyoResponse, setKlaviyoResponse] = useState()
  const { control, errors, handleSubmit } = useForm()

  const onSubmit = async (values) => {
    setIsLoading(true)
    const response = await subscribeToOutOfStock(
      values.email,
      variantId,
      productId
    )

    setKlaviyoResponse(response)
    setIsLoading(false)
  }

  if (!variantId || !productId) return null

  return (
    <Container>
      {isLoading && <Loader isLoading={isLoading} />}
      {klaviyoResponse &&
      klaviyoResponse.success &&
      !klaviyoResponse.data?.is_subscribed ? (
        <Success>
          <i class="fal fa-check-circle"></i>
          <p>
            {t(
              "Thank you! You will get an email once the product is back in stock."
            )}
          </p>
        </Success>
      ) : (
        <>
          <Title>{t("This product is out of stock")}</Title>
          <SubTitle>
            {t("Signup and be the first to know when it's back in stock!")}
          </SubTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={StyledInput}
              placeholder={t("Your e-mail")}
              label={t("Your e-mail")}
              control={control}
              name="email"
              type="email"
              rules={{
                required: t("This is a required field"),
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("Please supply a valid e-mail address")
                }
              }}
            />
            <SubmitButton hasIcon type="submit">
              {t("Submit")} <i className="fas fa-arrow-right"></i>
            </SubmitButton>
          </form>
          {Object.keys(errors).length !== 0 ? (
            <Message>
              <i className="fal fa-exclamation-circle"></i>
              {errors.email.message}
            </Message>
          ) : (
            klaviyoResponse &&
            klaviyoResponse.data && (
              <Message>
                {klaviyoResponse.data.is_subscribed ? (
                  <>
                    <i className="fal fa-exclamation-circle"></i>{" "}
                    {t("This email address has already subscribed")}
                  </>
                ) : klaviyoResponse.success ? (
                  <></>
                ) : (
                  <>
                    <i className="fal fa-exclamation-circle"></i>{" "}
                    {t("An error occured, please try again later.")}
                  </>
                )}
              </Message>
            )
          )}
        </>
      )}
    </Container>
  )
}

export default OutOfStockForm
