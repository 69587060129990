import React, { useContext } from "react"
import { Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../../../constants"
import { BrinkContext } from "../../../context/BrinkContext"
import { getGenderAttributes } from "../../../../helpers/getGenderAttributes"
import { getVariantStock } from "../../../../helpers/stock"
import { getColorName } from "../../../../helpers/getColorName"
import tortoiseSwatch from "../../../../images/colorswatches/tortoise.jpg"

const Container = styled.div`
  margin: 0 0 2.4rem;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.darkBorder};

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0 2.5rem;
  }
`

const Label = styled.span`
  width: 100%;
  text-transform: uppercase;
  display: block;
  font-size: 1.3rem;
  padding-bottom: 0.3rem;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -0.5rem;
  flex-wrap: wrap;
`

const ColorButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1.3rem;
  border: ${(p) =>
    p.active
      ? `0.2rem solid ${p.theme.colors.primary}`
      : `0.2rem solid ${p.theme.colors.darkBorder}`};
  color: ${(p) => p.theme.colors.black};
  cursor: ${(p) => (p.active ? "initial" : "pointer")};
  margin: 0.5rem;
  pointer-events: ${(p) => (p.active ? "none" : "all")};
  min-width: 5rem;
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  background-size: cover;
  background-position: center;
  ${(p) =>
    p.useColorAsSwitch
      ? p.backgroundColor === "tortoise"
        ? css`
            background-image: url(${tortoiseSwatch});
          `
        : css`
            background: ${(p) => p.theme.swatches[p.backgroundColor]};
          `
      : css`
          background-image: url(${(p) => p.backgroundImage});
          background-repeat: no-repeat;
        `}

  ${(p) =>
    p.outOfStock &&
    css`
      &:before {
        content: "";
        width: 4.5rem;
        height: 0.2rem;
        background: ${
          p.backgroundColor === "black" ||
          p.backgroundColor === "blackTt" ||
          p.backgroundColor === "silverGreen" ||
          p.backgroundColor === "blackOlive" ||
          p.backgroundColor === "lightGold" ||
          p.backgroundColor === "blue" ||
          p.backgroundColor === "brown" ||
          p.backgroundColor === "pine" ||
          p.backgroundColor === "jetBlack" ||
          p.backgroundColor === "carbonGrey" ||
          p.backgroundColor === "darkCamo" ||
          p.backgroundColor === "matteBlack" ||
          p.backgroundColor === "azureBlue" ||
          p.backgroundColor === "fadedGreen" ||
          p.backgroundColor === "fadedBrown" ||
          p.backgroundColor === "lightBlue" ||
          p.backgroundColor === "mocha" ||
          p.backgroundColor === "ivory" ||
          p.backgroundColor === "navy" ||
          p.backgroundColor === "darkBoulevard" ||
          p.backgroundColor === "redMerlot" ||
          p.backgroundColor === "amber" ||
          p.backgroundColor === "darkBlue" ||
          p.backgroundColor === "gunmetal" ||
          p.backgroundColor === "turtoise" || 
          p.backgroundColor === "specialBlack"
            ? p.theme.colors.white
            : p.theme.colors.black
        };
        position: absolute;
        transform: rotate(-45deg);
        left: 0;
        top: 2.1rem;
        z-index: 0;
        opacity: 1;
      }
    `};

    ${p => p.backgroundColor === "specialBlack" && css`
      &:after {  
        content: ""; 
        position: absolute;
        border-radius: 50%;
        left: 0.2rem; 
        right: 0.2rem; 
        top: 0.2rem; 
        bottom: 0.2rem;
        border: ${(p) =>
        `0.2rem solid ${p.theme.colors.primary}`
        };
      }
    `}

     ${p => p.backgroundColor === "black" && css`
      &:after {  
        content: ""; 
        position: absolute;
        border-radius: 50%;
        left: 0.2rem; 
        right: 0.2rem; 
        top: 0.2rem; 
        bottom: 0.2rem;
        border: ${(p) =>
        `0.2rem solid #ffffff`
        };
      }
    `}

    ${p => p.backgroundColor === "creamGreen" && css`
      &:after {  
        content: ""; 
        position: absolute;
        border-radius: 50%;
        left: 0.2rem; 
        right: 0.2rem; 
        top: 0.2rem; 
        bottom: 0.2rem;
        border: ${(p) =>
        `0.2rem solid #5B7F55`
        };
      }
    `}
    ${p => p.backgroundColor === "creamBlack" && css`
    &:after {  
      content: ""; 
      position: absolute;
      border-radius: 50%;
      left: 0.2rem; 
      right: 0.2rem; 
      top: 0.2rem; 
      bottom: 0.2rem;
      border: ${(p) =>
      `0.2rem solid #000000`
      };
    }
  `}

  transition: all 0.2s;
  opacity: ${(p) => (p.outOfStock ? "0.35" : "1")};

  &:hover {
    border: 0.2rem solid ${(p) => p.theme.colors.primary};
  }

  > span {
    display: ${(p) => (p.active ? "flex" : "none")};
  }
`

const CheckIcon = styled.span`
  top: 0.1rem;
  right: -0.5rem;
  position: absolute;
  background: ${(p) => p.theme.colors.white};
  border-radius: 1.2rem;
  width: 1.4rem;
  height: 1.4rem;
  justify-content: center;
  align-items: center;
  z-index: 2;

  i {
    font-size: 1.6rem;
    color: ${(p) => p.theme.colors.primary};
    max-height: 1.4rem;
  }
`


const ColorSelector = ({
  siblings,
  currentProduct,
  variants,
  gender,
  stocks
}) => {
  const { t } = useTranslation("translations")
  const { cart } = useContext(BrinkContext)

  if (!siblings) return false

  const products = [...new Set([currentProduct, ...siblings])]
    .filter((p) => p.active)
    .sort((a, b) => a.slug.current.localeCompare(b.slug.current))
    .map((p) => ({ id: p.id ? p.id : p._id, ...p }))

  return (
    <Container>
      <Label>
        {t("Color")}: {variants && getColorName(variants[0].color)}
      </Label>
      <Buttons>
        {products.map((product) => {
          const { images, pathPrefix } = getGenderAttributes(
            product.genderAttributes,
            gender
          )
          const { slug, id, _id, variants, useColorAsSwitch } = product

          const outOfStock =
            stocks &&
            variants.every(
              (variant) => getVariantStock(variant, stocks, cart) === 0
            )

          return (
            <Link
              to={
                pathPrefix
                  ? `/${pathPrefix}/${slug.current}/`
                  : `/${slug.current}/`
              }
              key={id}
              state={{ disableScrollUpdate: true }}
              title={getColorName(variants[0].color)}
              outOfStock={outOfStock}
            >
              <ColorButton
                useColorAsSwitch={useColorAsSwitch}
                backgroundColor={variants[0].color}
                backgroundImage={getSrc(images[0]?.asset.smallImage)}
                outOfStock={outOfStock}
                active={
                  currentProduct && _id === currentProduct._id
                    ? "active"
                    : undefined
                }
              >
                <CheckIcon>
                  <i className="fas fa-check-circle"></i>
                </CheckIcon>
              </ColorButton>
            </Link>
          )
        })}
      </Buttons>
    </Container>
  )
}

export default ColorSelector
