import React from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../../../constants"

const Container = styled.div`
  order: 2;
  padding: 1rem 0;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.2rem;
  flex: 1;

  h2 {
    margin-bottom: 3rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding-left: 5rem;
  }

  ${MEDIA_MIN_LARGE} {
    font-size: 1.5rem;

    h2 {
      margin-bottom: 4rem;
    }
  }

  a {
    color: ${(p) => p.theme.colors.black};
  }

  ul {
    padding: 1rem 0;
    list-style: none;

    li {
      position: relative;
      padding: 1.2rem 2.5rem 1.2rem 3rem;

      &:before {
        position: absolute;
        left: 0;
        top: 1.2rem;
        font-size: 1.8rem;
        color: ${(p) => p.theme.colors.primary};
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        content: "\f00c";
      }
    }
  }
`

const Description = ({ languageCode, productDescription }) => {
  const { t } = useTranslation("translations")

  return (
    <Container>
      <h2>{t("Product details")}</h2>
      {productDescription && (
        <BlockContent
          blocks={
            productDescription[languageCode]
              ? productDescription[languageCode]._rawText
              : productDescription.en._rawText
          }
        />
      )}
    </Container>
  )
}

export default Description
