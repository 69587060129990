export const getGenderAttributes = (genderAttributes, gender) => {
  if (!genderAttributes) return {}

  const maleAttributes = {
    active: genderAttributes.maleActive,
    images: genderAttributes.maleImages,
    description: genderAttributes.maleDescription,
    video: genderAttributes.maleVideo,
    sizeGuide: genderAttributes.maleSizeGuide,
    pathPrefix: "dudes"
  }

  const femaleAttributes = {
    active: genderAttributes.femaleActive,
    images: genderAttributes.femaleImages,
    description: genderAttributes.femaleDescription,
    video: genderAttributes.femaleVideo,
    sizeGuide: genderAttributes.femaleSizeGuide,
    pathPrefix: "ladies"
  }

  const unisexAttributes = {
    active: genderAttributes.unisexActive,
    images: genderAttributes.unisexImages,
    description: genderAttributes.unisexDescription,
    video: genderAttributes.unisexVideo,
    sizeGuide: genderAttributes.unisexSizeGuide,
    pathPrefix: null
  }

  if (gender === "ladies") return femaleAttributes
  if (gender === "dudes") return maleAttributes
  if (gender === "unisex") return unisexAttributes
  if (genderAttributes.maleUseAsDefault) return maleAttributes
  if (genderAttributes.femaleUseAsDefault) return femaleAttributes
  if (genderAttributes.unisexUseAsDefault) return unisexAttributes
  return {}
}
