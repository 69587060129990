import React, { useState } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "../../../../constants"
import SizeGuide from "./SizeGuide"
import { getRegions, getSize } from "../../../../helpers/shoeSizes"

const Container = styled.div`
  position: relative;
  margin: 0 0 1rem;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.darkBorder};
`

const Label = styled.span`
  width: 100%;
  text-transform: uppercase;
  display: block;
  font-size: 1.3rem;
  padding-bottom: 0.3rem;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -0.5rem;
  align-items: center;
  flex-wrap: wrap;
`

const SizeButton = styled.div`
  a {
    border: ${(p) =>
      p.active
        ? `0.2rem solid ${p.theme.colors.primary}`
        : p.outOfStock
        ? `0.2rem solid ${p.theme.colors.lightBorder}`
        : `0.2rem solid ${p.theme.colors.darkerBorder}`};
    color: ${(p) =>
      p.outOfStock ? p.theme.colors.mediumGrey : p.theme.colors.black};
    pointer-events: ${(p) => (p.active ? "none" : "all")};
    cursor: ${(p) => (p.active ? "initial" : "pointer")};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 1.3rem;
    background: ${(p) => p.theme.colors.white};
    margin: 0.5rem;
    min-width: 4.8rem;
  
    height: 4.8rem;
    padding-left: 0.2rem;
    border-radius: 2.5rem;
    transition: all 0.2s;
    text-align: center;

    ${MEDIA_MIN_LARGE} {
      &:hover {
        border: 0.2rem solid ${(p) => p.theme.colors.darkGrey};
      }
    }

    svg {
      width: 2.4rem;
    }

    ${(p) =>
      p.outOfStock &&
      css`
        &:before {
          content: "";
          width: 4.3rem;
          height: 0.2rem;
          background: ${p.theme.colors.lightBorder};
          position: absolute;
          transform: rotate(-45deg);
          left: 0;
          top: 2rem;
          z-index: 0;
        }
      `}

    > span {
      display: ${(p) => (p.active ? "flex" : "none")};
    }
  }
`

const SizeSwitch = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0 1rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 1rem 0 2rem;
  }
`

const RegionLink = styled.div`
  margin: 0.5rem 2rem;
  padding-bottom: 0.5rem;
  height: 2.5rem;
  border-bottom: ${(p) => (p.active ? "0.2rem solid" : "0.2rem transparent")};
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.4rem;

  ${MEDIA_MIN_MEDIUM} {
    &:hover {
      border-bottom: 0.2rem solid;
    }
  }
`

const SizeGuideLink = styled.div`
  font-size: 1.3rem;
  margin-left: 1rem;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0;
`

const CheckIcon = styled.span`
  top: 0.1rem;
  right: -0.5rem;
  position: absolute;
  background: ${(p) => p.theme.colors.white};
  border-radius: 1.2rem;
  width: 1.4rem;
  height: 1.4rem;
  justify-content: center;
  align-items: center;

  i {
    font-size: 1.6rem;
    color: ${(p) => p.theme.colors.primary};
    max-height: 1.4rem;
  }
`

const SizeSelector = ({
  currentVariant,
  variants,
  sizeGuide,
  gender,
  stocks
}) => {
  const { t } = useTranslation("translations")
  const [openPopup, setOpenPopup] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState("EU")
  const regions = getRegions(variants[0].size)

  return (
    <>
      <Container>
        <Label>
          {t("Size")}:
          {currentVariant && (
            <>
              {" "}
              {getSize(currentVariant.size, selectedRegion) ||
                currentVariant.size.toUpperCase()}
            </>
          )}
        </Label>
        {sizeGuide && (
          <SizeGuideLink onClick={() => setOpenPopup(true)}>
            <i className="fal fa-ruler-combined"></i> {t("Size guide")}
          </SizeGuideLink>
        )}
        {regions.length > 1 && (
          <SizeSwitch>
            {regions.map((region) => (
              <RegionLink
                key={region}
                active={region === selectedRegion}
                onClick={() => setSelectedRegion(region)}
              >
                {region}
              </RegionLink>
            ))}
          </SizeSwitch>
        )}
        {variants?.length > 1 && (
          <Buttons>
            {variants.map((variant) => {
              const { slug, size, outOfStock } = variant
              const formattedSize =
                getSize(size, selectedRegion) || size.toUpperCase()

              return (
                <SizeButton
                  active={currentVariant?.size === size ? "active" : undefined}
                  outOfStock={stocks && outOfStock}
                  key={size}
                >
                  <Link
                    to={
                      gender === "unisex" ? `/${slug}/` : `/${gender}/${slug}/`
                    }
                    title={
                      outOfStock
                        ? t("Out of stock")
                        : regions.length > 1
                        ? `Size: ${selectedRegion.toUpperCase()} ${formattedSize}`
                        : `Size: ${formattedSize}`
                    }
                    state={{ disableScrollUpdate: true }}
                  >
                    {formattedSize}
                    <CheckIcon>
                      <i className="fas fa-check-circle"></i>
                    </CheckIcon>
                  </Link>
                </SizeButton>
              )
            })}
          </Buttons>
        )}
      </Container>

      {sizeGuide && (
        <SizeGuide
          sizeGuide={sizeGuide}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        />
      )}
    </>
  )
}

export default SizeSelector
